<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
        @keydown.enter="ok"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card flat>
                    <v-card-text
                        v-if="loading"
                    >
                        <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                        >
                            {{ $t("Загрузка...") }}
                        </v-col>

                        <v-col cols="12">
                            <v-progress-linear
                                color="#a94442"
                                indeterminate
                                rounded
                                height="6"
                            ></v-progress-linear>
                        </v-col>

                    </v-card-text>
                    <v-card-text v-else>
                        <v-row no-gutters>

                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Профайл") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-select
                                    v-model="profile"
                                    :items="profiles"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                </v-select>
                            </v-col>

                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="!loading && profile"
                >
                    {{ $t("Ок") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "PromptProfileDlg",
    data () {
        return {
            title: "Выберите_профайл",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('signing',
            { 
                visible: 'isPromptProfileVisible',
                loading: 'isPromptProfileLoading',
                profiles: 'getProfiles'
            }),
        profile: {
            get: function() {
                return this.$store.getters['signing/getProfile'];
            },
            set: function(v) {
                this.$store.commit('signing/SET_PROFILE', v); 
            }
        },
    },
    methods: {
        ...mapActions('signing', { ok: 'profileOk', cancel: 'profileCancel' })
    }
}
</script>